<!--
 * @Author: Dyf
 * @Date: 2023-02-18 18:24:03
 * @LastEditors: Dyf
 * @LastEditTime: 2023-06-20 16:57
 * @Description: 滑动校验码
-->
<style lang="scss" scoped>
*{
  transition: all 0.1s !important;
}
@keyframes loading {
  0% {
    opacity: 0.7;
  }

  100% {
    opacity: 9;
  }
}

.slide-verify {
  position: relative;

  &-loading {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.9);
    z-index: 999;
    animation: loading 1.5s infinite;
  }

  &-block {
    position: absolute;
    left: 0;
    top: 0;
  }

  &-refresh {
    &-icon {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      cursor: pointer;
      position: absolute;
      top: 30px;
      right: 34px;
      z-index: 20;
      background: rgba($color: #000000, $alpha: .48);
      font-size: 24px;
      color: rgba($color: #fff, $alpha: 0.8);
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &-result {
    background: rgba($color: #fff, $alpha: 0.8);
    z-index: 20;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 6px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    line-height: 3em;

    .iconfont {
      font-size: 40px;
    }

    &.fail {
      color: #ee4f65;
    }

    &.success {
      color: #2ac293;
    }
  }

  &-slider {
    width: 410px;
    height: 52px;
    line-height: 52px;
    position: relative;
    text-align: center;
    background: #edf2f5;
    color: #616161;
    margin: 28px auto 0;
    border-radius: 10px;
    overflow: hidden;

    &-mask {
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      background: #6c4ecb;
      border-radius: 10px 0 0 10px;

      &-item {
        width: 58px;
        height: 52px;
        background: #fff;
        border-radius: 0 10px 10px 0;
        cursor: pointer;
        position: absolute;
        top: 0;
        left: 0;
        font-size: 18px;

        &:hover {
          box-shadow: 0px 0px 10px 5px rgba($color: #c0afee, $alpha: 0.43) inset;
        }

        &-icon {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }

    &-text {
      color: #616161;
    }

    &.container-success {
      .slide-verify-slider-mask {
        background-color: #2ac293;

        &-item {
          color: #2ac293;

          &:hover {
            box-shadow: none;
          }
        }
      }
    }

    &.container-fail {
      .slide-verify-slider-mask {
        background-color: #ee4f65;

        &-item {
          color: #ee4f65;

          &:hover {
            box-shadow: none;
          }
        }
      }
    }

    &.container-active,
    &.container-success,
    &.container-fail {
      .slide-verify-slider-text {
        opacity: 0;
      }
    }
  }
}
</style>

<template>
  <div class="slide-verify" :style="{ width: w + 'px' }" id="slideVerify" onselectstart="return false;">
    <!-- 刷新控件 -->
    <div v-if="show" @click="refresh" class="slide-verify-refresh-icon iconfont">&#xe608;</div>
    <!-- 图片加载遮蔽罩 -->
    <div :class="{ 'slide-verify-loading': loadBlock }"></div>
    <!-- 校验结果遮罩 -->
    <div class="slide-verify-result" :class="{ 'success': containerSuccess, 'fail': containerFail }"
      v-if="containerSuccess || containerFail" :style="{ width: w + 'px', height: h + 'px' }">
      <p class="iconfont">
        {{ containerSuccess && '&#xe606;' || '&#xe605;' }}
      </p>
      <p> {{ containerSuccess && '校验成功' || '校验失败' }}</p>
    </div>
    <!-- 背景图 -->
    <canvas :width="w" :height="h" ref="canvas" style="border-radius: 6px;"></canvas>
    <!-- 拼图滑块 -->
    <canvas :width="w" :height="h" ref="block" class="slide-verify-block"></canvas>
    <!-- 底部校验滑动条 -->
    <div class="slide-verify-slider"
      :class="{ 'container-active': containerActive, 'container-success': containerSuccess, 'container-fail': containerFail }"
      :style="{ width: sliderWidth + 'px' }">
      <div class="slide-verify-slider-mask" :style="{ width: sliderMaskWidth }">
        <!-- 滑块 -->
        <div @mousedown="sliderDown" @touchstart="touchStartEvent" @touchmove="handleMoveEvent($event, 'touch')"
          @touchend="handleMoveEndEvent($event, 'touch')" class="slide-verify-slider-mask-item"
          :style="{ left: sliderLeft }">
          <p class="slide-verify-slider-mask-item-icon iconfont">&#xe607;</p>
        </div>
      </div>
      <span class="slide-verify-slider-text">{{ sliderText }}</span>
    </div>
  </div>
</template>

<script>
const PI = Math.PI;

function sum(x, y) {
  return x + y;
}

function square(x) {
  return x * x;
}
export default {
  name: "SlideVerify",
  props: {
    // 滑块边长
    blockSlide: {
      type: Number,
      default: 42
    },
    // 滑块突出半径
    blockRadius: {
      type: Number,
      default: 10
    },
    // 滑块描边
    blockLine: {
      type: Number,
      default: 2
    },
    sliderWidth: {
      type: Number,
      default: 310
    },
    // 画布宽
    w: {
      type: Number,
      default: 310
    },
    // 画布高
    h: {
      type: Number,
      default: 155
    },
    // 底部提示文本
    sliderText: {
      type: String,
      default: "Slide filled right"
    },
    // 滑动验证的误差范围
    accuracy: {
      type: Number,
      default: 8 // 若为 -1 则不进行机器判断
    },
    // 显示刷新按钮
    show: {
      type: Boolean,
      default: true
    },
    // 背景图组
    imgs: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      containerActive: false, // 验证滑动时类名
      containerSuccess: false, // 验证成功时类名
      containerFail: false, // 验证失败时类名
      canvasCtx: null, // canvas实例化对象【背景】
      blockCtx: null, // canvas实例化对象【滑块】
      block: null, // 滑块
      block_x: undefined, // 滑块定位：x
      block_y: undefined, // 滑块定位：y
      L: this.blockSlide + this.blockRadius * 2 + 3, // 滑块实际边长
      img: undefined, // canvas绘制img
      originX: undefined,
      originY: undefined,
      isMouseDown: false,
      trail: [],
      sliderLeft: -2 + 'px', // block right offset
      sliderMaskWidth: 0, // mask width,
      noMove: false, // Bug Fixes 修复了验证成功后还能滑动
      loadBlock: true, // Features 图片加载提示，防止图片没加载完就开始验证
      timestamp: null
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    /**
     * 页面初始化
     */
    async init() {
      await this.initDom();
      await this.initImg();
      await this.bindEvents();
    },
    /**
     * 初始化画布
     */
    initDom() {
      this.block = this.$refs.block;
      this.canvasCtx = this.$refs.canvas.getContext("2d");
      this.blockCtx = this.block.getContext("2d");
    },
    /**
     * 初始化图片
     */
    initImg() {
      const img = this.createImg(() => {
        // 图片加载完关闭遮蔽罩
        this.loadBlock = false;
        this.drawBlock(); // 绘制滑块
        this.canvasCtx.drawImage(img, 0, 0, this.w, this.h);
        this.blockCtx.drawImage(img, 0, 0, this.w, this.h);
        let { block_x: x, block_y: y, blockRadius, L } = this;
        let _y = y - blockRadius * 2 - 1;
        let ImageData = this.blockCtx.getImageData(x, _y, L, L);
        this.block.width = L;
        this.blockCtx.putImageData(ImageData, 0, _y);
      });
      this.img = img;
    },
    /**
     * 绘制滑块
     */
    drawBlock() {
      this.block_x = this.getRandomNumberByRange(this.L + 10, this.w - (this.L + 10));
      this.block_y = this.getRandomNumberByRange(10 + this.blockRadius * 2, this.h - (this.L + 10));
      this.draw(this.canvasCtx, this.block_x, this.block_y, "fill");
      this.draw(this.blockCtx, this.block_x, this.block_y, "clip");
    },
    /**
     * 绘制画布
     */
    draw(ctx, x, y, operation) {
      let { blockSlide, blockRadius } = this;
      ctx.beginPath();
      ctx.moveTo(x, y);
      ctx.arc(x + blockSlide / 2, y - blockRadius + this.blockLine, blockRadius, 0.72 * PI, 2.26 * PI); // 滑块上圆
      ctx.lineTo(x + blockSlide, y);
      ctx.arc(x + blockSlide + blockRadius - this.blockLine, y + blockSlide / 2, blockRadius, 1.21 * PI, 2.78 * PI); // 滑块右圆
      ctx.lineTo(x + blockSlide, y + blockSlide);
      ctx.lineTo(x, y + blockSlide);
      ctx.arc(x + blockRadius - this.blockLine, y + blockSlide / 2, blockRadius, 2.76 * PI, 1.24 * PI, true); // 滑块左圆
      ctx.lineTo(x, y);
      ctx.lineWidth = this.blockLine;
      ctx.fillStyle = "rgba(255, 255, 255, 0.67)";
      ctx.strokeStyle = "rgba(255, 255, 255, 0.67)";
      ctx.stroke();
      ctx[operation]();
      // Bug Fixes 修复了火狐和ie显示问题
      ctx.globalCompositeOperation = "destination-over";
    },
    /**
     * 生成图片
     */
    createImg(onload) {
      const img = new Image();
      img.crossOrigin = "anonymous";
      img.onload = onload;
      img.onerror = () => {
        const imgArr = ['https://img2.baidu.com/it/u=1047038798,3970070687&fm=253&fmt=auto&app=138&f=JPEG?w=889&h=500', 'https://img2.baidu.com/it/u=2857834508,3069085961&fm=253&fmt=auto&app=138&f=JPEG?w=1003&h=500']
        img.src = imgArr[this.getRandomNumberByRange(0, imgArr.length - 1)];
      };
      img.src = this.getRandomImg();
      return img;
    },
    /**
     * 获取随机校验图片
     */
    getRandomImg() {
      const len = this.imgs.length;
      return len > 0 ? this.imgs[this.getRandomNumberByRange(0, len - 1)] : '';
    },
    /**
     * 获取随机值
     * @param {Number} start 开始
     * @param {Number} end 结束
     */
    getRandomNumberByRange(start, end) {
      return Math.round(Math.random() * (end - start) + start);
    },
    /**
     * 校验滑块松开
     * @param {*} event 
     */
    sliderDown(event) {
      if (this.noMove) return;
      this.originX = event.clientX;
      this.originY = event.clientY;
      this.isMouseDown = true;
      this.timestamp = +new Date();
    },
    /**
     * 校验滑块点击按下
     * @param {*} e 
     */
    touchStartEvent(e) {
      if (this.noMove) return;
      this.originX = e.changedTouches[0].pageX;
      this.originY = e.changedTouches[0].pageY;
      this.isMouseDown = true;
      this.timestamp = +new Date();
    },
    /**
     * 事件触发初始化
     */
    bindEvents() {
      document.addEventListener("mousemove", this.handleMoveEvent);
      document.addEventListener("mouseup", this.handleMoveEndEvent);
    },
    /** 处理函数抽离（鼠标移动）*/
    handleMoveEvent: throttle(function (e, type = "mouse") {
      if (!this.isMouseDown) return false;
      const moveX = type === "mouse" ? e.clientX - this.originX : e.changedTouches[0].pageX - this.originX;
      const moveY = type === "mouse" ? e.clientY - this.originY : e.changedTouches[0].pageY - this.originY;
      if (moveX < 0 || moveX + this.blockSlide - 2 >= this.sliderWidth) return false;
      let p = this.w / this.sliderWidth
      this.sliderLeft = moveX + "px";
      let blockLeft = ((this.w - this.blockSlide - this.blockRadius) / (this.w - this.blockSlide)) * moveX * p;
      this.block.style.left = blockLeft + "px";
      this.containerActive = true;
      this.sliderMaskWidth = moveX + "px";
      this.trail.push(moveY);
    }),
    /** 处理拖动结束（鼠标松开） */
    handleMoveEndEvent(e, type = "mouse") {
      if (!this.isMouseDown) return false;
      this.isMouseDown = false;
      if (
        (type === "mouse" && e.clientX === this.originX) ||
        (type === "touch" && e.changedTouches[0].pageX === this.originX)
      )
        return false;
      this.containerActive = false; // remove active
      this.timestamp = +new Date() - this.timestamp;

      const { spliced, TuringTest } = this.verify();
      if (spliced) {
        if (this.accuracy === -1) {
          console.log('校验success && accuracy = -1')
          this.containerSuccess = true;
          this.noMove = true;
          this.$emit("success", this.timestamp);
          return;
        }
        if (TuringTest) {
          console.log('校验success && TuringTest =', TuringTest)
          this.containerSuccess = true;
          this.noMove = true;
          this.$emit("success", this.timestamp);
        } else {
          console.log('校验again && TuringTest =', TuringTest)
          this.containerFail = true;
          this.noMove = true;
          this.$emit("again");
        }
      } else {
        console.log('校验fail && spliced = ', spliced)
        this.containerFail = true;
        this.noMove = true;
        this.$emit("fail");
      }
    },
    /**
     * 校验
     */
    verify() {
      const arr = this.trail; // drag y move distance
      const average = arr.reduce(sum) / arr.length; // average
      const deviations = arr.map(x => x - average); // deviation array
      const stddev = Math.sqrt(deviations.map(square).reduce(sum) / arr.length); // standard deviation
      const left = parseInt(this.block.style.left);
      const accuracy =
        this.accuracy <= 1 ? 1 : this.accuracy > 10 ? 10 : this.accuracy;
      return {
        spliced: Math.abs(left - this.block_x) <= accuracy,
        TuringTest: average !== stddev // equal => not person operate
      };
    },
    /**
     * 刷新
     */
    refresh() {
      console.log('刷新图片');
      this.$emit("refresh");
      this.$nextTick(()=>{
        this.reset();
      })
    },
    /**
     * 重置
     */
    reset() {
      console.log('重置图片画布')
      this.noMove = false;
      this.containerActive = false;
      this.containerSuccess = false;
      this.containerFail = false;
      this.sliderLeft = -2 + 'px';
      this.block.style.left = 0;
      this.sliderMaskWidth = 0;
      // 重置画布
      let { w, h } = this;
      this.canvasCtx.clearRect(0, 0, w, h);
      this.blockCtx.clearRect(0, 0, w, h);
      this.block.width = w;
      console.log('重置图片成功')
      // 图片重载
      this.initImg();
      // this.img.src = this.getRandomImg();
      this.$emit("fulfilled");
    }
  },
  destroyed() {
    document.removeEventListener("mousemove", this.handleMoveEvent);
    document.removeEventListener("mouseup", this.handleMoveEndEvent);
  }
};

function throttle(
  fn,
  interval = 50,
  options = { leading: true, trailing: true }
) {
  const { leading, trailing, resultCallback } = options;
  let lastTime = 0;
  let timer = null;

  const _throttle = function (...args) {
    return new Promise((resolve, reject) => {
      const nowTime = new Date().getTime();
      if (!lastTime && !leading) lastTime = nowTime;

      const remainTime = interval - (nowTime - lastTime);
      if (remainTime <= 0) {
        if (timer) {
          clearTimeout(timer);
          timer = null;
        }

        const result = fn.apply(this, args);
        if (resultCallback) resultCallback(result);
        resolve(result);
        lastTime = nowTime;
        return;
      }

      if (trailing && !timer) {
        timer = setTimeout(() => {
          timer = null;
          lastTime = !leading ? 0 : new Date().getTime();
          const result = fn.apply(this, args);
          if (resultCallback) resultCallback(result);
          resolve(result);
        }, remainTime);
      }
    });
  };

  _throttle.cancel = function () {
    if (timer) clearTimeout(timer);
    timer = null;
    lastTime = 0;
  };

  return _throttle;
}
</script>
